<template>
  <v-select
    :items="mockItem"
    :value="mockValue"
    outlined
    background-color="white"
    hide-details
    clearable
    item-color="white"
    :menu-props="{ contentClass: 'task-colors-select' }"
    :label="$t('schedule_new_op_dialog.select_color')"
    @click:clear="clearColor"
  >
    <template #selection>
      <v-row
        class="task-colors-select"
        align="center" justify="start"
      >
        <span
          class="selected-chip"
          :style="{'background-color': value}"
        />
      </v-row>
    </template>
    <template #item>
      <v-row
        align="center" justify="space-between" no-gutters
      >
        <v-col cols="12" class="pps-2 color2 t-400-16 mb-3">
          {{ $t('schedule_new_op_dialog.select_color') }}
        </v-col>
        <span
          v-for="(color, index) in allColors" :key="index"
          class="color-chip mms-2"
          :class="{ 'chip-selected': value === color }"
          :style="{'background-color': color}"
          @click="onColorClick(color)"
        >
          <v-icon v-if="value === color" class="color1">
            check
          </v-icon>
        </span>
      </v-row>
    </template>
  </v-select>
</template>
<script>
import { watch, ref } from 'vue'
export default {
  props: {
    value: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup (props, { emit }) {
    const mockItem = [1]
    const mockValue = ref(null)
    const allColors = ['#E1CBF2', '#FDE9D4', '#F2E5CB', '#D6EDF0', '#CBD5F2', '#CBF2DB', '#F2CBCB']

    // Methods
    const clearColor = () => emit('click:clear')
    const onColorClick = (color) => emit('input', color)

    watch(() => props.value, (newColor) => {
      mockValue.value = allColors.some((color) => color === newColor) ? 1 : null
    }, { immediate: true })
    return {
      allColors,
      mockItem,
      mockValue,
      onColorClick,
      clearColor
    }
  }
}
</script>
<style lang="scss">
.task-colors-select {
  .color-chip{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: calc(100% / 7 - 8px);
    aspect-ratio: 1 / 1;
    border: 1px solid #DADADA;
    margin-bottom: 8px;
    cursor: pointer;
    &.chip-selected {
      border: 1px solid #0040FF;
    }
  }
  .selected-chip {
    border-radius: 50%;
    width: 34px;
    height: 34px;
  }
}
</style>
