<template>
  <v-select
    :items="notificationsOptions"
    outlined
    multiple
    hide-details
    background-color="white"
    :item-disabled="isItemDisabled"
    :label="$t('single_task_notification.title')"
    @change="onChange"
  >
    <template #item="{ item, attrs }">
      <v-row
        align="center" justify="space-between"
        :class="{ 'rtl': isRtl, 'ltr': !isRtl }"
      >
        <v-col cols="10">
          {{ item.text }}
        </v-col>
        <v-col cols="2" class="texts-end">
          <v-icon v-if="attrs.inputValue">
            check_box
          </v-icon>
          <v-icon v-else>
            check_box_outline_blank
          </v-icon>
        </v-col>
      </v-row>
    </template>
  </v-select>
</template>
<script>
import { i18n, isRtl } from '@/i18n.js'
const values = { taskComplete: 1, taskUnComplete: 2 }
export default {
  props: {
    taskUnCompleteDisabled: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    const notificationsOptions = [
      { text: i18n.t('single_task_notification.on_complete'), value: values.taskComplete },
      {
        text: i18n.t('single_task_notification.on_un_complete'),
        value: values.taskUnComplete
      }
    ]

    const createChangeValue = (changeValue) => {
      const result = {
        taskComplete: changeValue.includes(values.taskComplete),
        taskUncomplete: changeValue.includes(values.taskUnComplete)
      }

      return result
    }
    // Methods
    const onChange = (value) => emit('change', createChangeValue(value))
    const isItemDisabled = (item) => item.value === values.taskUnComplete && props.taskUnCompleteDisabled
    return {
      notificationsOptions,
      isRtl,
      onChange,
      isItemDisabled
    }
  }
}
</script>
