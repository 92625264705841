<template>
  <v-select
    :value="value"
    :items="shiftParts"
    :menu-props="{ 'contentClass': isRtl ? 'rtl' : 'ltr' }"
    :disabled="disabled"
    item-text="translateName"
    item-value="name"
    single-line
    return-object
    outlined
    hide-details
    background-color="white"
    @input="onInput"
  >
    <template #item="{ item }">
      <v-row
        align="center" justify="space-between"
        :class="{ 'rtl': isRtl, 'ltr': !isRtl }"
        no-gutters
      >
        <v-col cols="10">
          {{ item.translateName }}
        </v-col>
        <v-col cols="2" class="texts-end">
          <v-checkbox
            :input-value="item.name === value.name"
            off-icon="radio_button_unchecked" on-icon="radio_button_checked"
            hide-details class="mt-0 pt-0 d-inline-block"
          />
        </v-col>
      </v-row>
    </template>
  </v-select>
</template>
<script>
import { shiftPartsForSelect } from '@/store/modules/OperationSchedule.js'

import { isRtl } from '@/i18n.js'
export default {
  props: {
    value: {
      type: Object,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup (_, { emit }) {
    const shiftParts = shiftPartsForSelect()

    // Methods
    const onInput = (value) => emit('input', value)
    return {
      shiftParts,
      isRtl,
      onInput
    }
  }
}
</script>
