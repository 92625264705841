<template>
  <v-dialog
    v-model="dialog" persistent :fullscreen="$vuetify.breakpoint.xsOnly"
    :width="540"
    content-class="rounded-xl"
  >
    <item-operation-list-dialog ref="itemOperationListDialog" :api-key="apiKey" />
    <v-card
      class="bg-color17 new-task-dialog"
      :class="{ 'rtl': isRtl, 'ltr': !isRtl }"
    >
      <v-card-title class="white px-1">
        <v-row align="center" justify="start">
          <v-col cols="2" class="texts-start">
            <v-btn
              :disabled="isLoading"
              icon
              color="pink"
              @click="close"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="8" class="text-center color5 t-600-18">
            {{ $t('schedule_new_op_dialog.title') }}
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="scrollable px-2">
        <v-form ref="operationForm">
          <v-row
            align="start" justify="center"
            class="overflow-x-hidden"
          >
            <v-col cols="12" class="mt-4 pb-0">
              <v-textarea
                v-model="newOperation.description"
                background-color="white"
                outlined
                auto-grow
                rows="4"
                counter="240"
                :label="$t('schedule_new_op_dialog.description')"
              />
            </v-col>
            <v-col cols="12">
              <job-selection-search
                v-if="dialog"
                ref="jobSearch"
                v-model="currentJobSearchResult"
                class="t-400-16 color2"
                outlined
                hide-details
                outer-class="required"
                background-color="white"
                :api-key="apiKey"
                :label="$t('schedule_new_op_dialog.choose_job')"
                :menu-props="{ 'contentClass': isRtl ? 'rtl' : 'ltr' }"
                :search-for-operation="searchForOperation"
                @input="onJobSelect"
              />
            </v-col>
            <v-col cols="12">
              <task-frequency-field
                :frequency-model.sync="frequencyModel"
                :frequency-exect-date.sync="frequencyExectDate"
                :frequency-every-year.sync="frequencyYearDate"
                :end-date.sync="endDate"
                :disabled="!isJobSelected"
                :job-active-days="activeDays"
                :single-every-year-date="isUpdateMode"
                @update-working-days="updateWorkingDays"
              />
            </v-col>
            <v-col cols="12" class="pt-0">
              <v-btn
                text color="#0040FF" class="px-0"
                @click="toggleMoreDetails"
              >
                <v-icon>{{ moreDetailsOpen ? 'expand_less' : 'expand_more' }}</v-icon>
                {{ $t('show_more_details') }}
              </v-btn>
              <v-expand-transition>
                <v-row
                  v-show="moreDetailsOpen" align="start" justify="center"
                  no-gutters
                >
                  <v-col cols="12" class="mt-2">
                    <task-shift-part-field v-model="shiftPart" :disabled="shiftPartDisabled" />
                  </v-col>
                  <v-col cols="12" class="mt-4">
                    <time-picker-dialog
                      v-model="startTime"
                      background-color="white"
                      outlined
                      hide-details
                    />
                  </v-col>
                  <v-col cols="12" class="mt-4">
                    <task-color-select
                      v-model="newOperation.color"
                      @click:clear="clearColor"
                    />
                  </v-col>
                  <v-col cols="12" class="mt-3">
                    <div class="switch-div row no-gutters align-center justify-space-between pointer my-2">
                      <v-col
                        class="flex-grow-1 flex-shrink-0 pps-2 t-400-16"
                        :class="{ 'disabled': hasListItem }"
                        @click="toggleRequireImage"
                      >
                        {{ $t('schedule_new_op_dialog.require_image') }}
                      </v-col>
                      <v-col class="flex-grow-0 flex-shrink-1">
                        <v-switch
                          v-model="requireImage"
                          :disabled="hasListItem"
                          class="px-2 d-inline-block ltr"
                        />
                      </v-col>
                    </div>
                  </v-col>
                  <v-col v-if="!isUpdateMode" cols="12" class="mt-4">
                    <task-notification-field
                      :task-un-complete-disabled="!operationHasStartTime"
                      @change="taskNotificationChange"
                    />
                  </v-col>
                  <v-col cols="12" class="mt-4">
                    <task-report-tag-field v-model="reportTag" />
                  </v-col>
                  <v-col cols="12" class="mt-4">
                    <item-search
                      v-model="relatedItem"
                      :place-holder="$t('schedule_new_op_dialog.related_item')"
                      :api-key="apiKey"
                      show-add-item
                      show-delete-button
                      show-edit-button
                      :new-item-place-holder="$t('schedule_new_op_dialog.new_item')"
                      load-all
                      outlined
                      hide-details
                      background-color="white"
                    />
                  </v-col>
                  <v-col cols="12" class="mt-4">
                    <file-uploader
                      ref="opFile"
                      class="file-input color5 t-600-18"
                      underline
                      color="transparent"
                      record-type="ItemOperation"
                    />
                  </v-col>
                </v-row>
              </v-expand-transition>
            </v-col>

            <v-col cols="3" class="pt-6 text-center">
              <v-btn
                small icon :disabled="requireImage"
                @click="openOperationListItem"
              >
                <v-icon :color="hasListItem ? 'green' : 'black'">
                  format_list_bulleted
                </v-icon>
              </v-btn>
            </v-col>
            <v-col cols="9">
              <operation-guidence-images
                ref="opImages"
                record-type="ItemOperation"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-row align="center" justify="center">
          <v-col cols="7">
            <v-btn
              color="black" class="save-btn white--text" block
              depressed tile
              :loading="isLoading" :disabled="saveDisabled"
              @click="saveClick"
            >
              {{ $t('save') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import JobSelectionSearch from '@/components/job_selection_search/JobSelectionSearch.vue'
import ItemOperationListDialog from '@/components/operation_schedule_dialog/item_operation_list/ItemOperationListDialog.vue'
import OperationGuidenceImages from '@/components/operation_schedule_dialog/OperationGuidenceImages.vue'
import TimePickerDialog from '@/components/shared/TimePickerDialog.vue'
import TaskFrequencyField from '@/components/operation_schedule_dialog/task_frequency/TaskFrequencyField.vue'
import TaskColorSelect from '@/components/operation_schedule_dialog/TaskColorSelect.vue'
import TaskNotificationField from '@/components/operation_schedule_dialog/TaskNotificationField.vue'
import FileUploader from '@/components/shared/FileUploader.vue'
import ItemSearch from '@/components/shared/item_search/ItemSearch.vue'
import TaskReportTagField from '@/components/operation_schedule_dialog/TaskReportTagField.vue'
import TaskShiftPartField from '@/components/operation_schedule_dialog/TaskShiftPartField.vue'
import useNewOperation from '@/composables/useNewOperation.js'
import useAuth from '@/composables/useAuth.js'

import { isString } from '@/helpers/Utils.js'
import { shiftPartsForSelect as OpModuleShiftParts } from '@/store/modules/OperationSchedule.js'

import { EventBus, NEW_ACCOUNT_OPERATION } from '@/EventBus.js'

import { ref, computed, nextTick } from 'vue'

import { isRtl } from '@/i18n.js'
const DialogMode = { create: 1, update: 2, copy: 3 }

export default {
  components: {
    'job-selection-search': JobSelectionSearch,
    'item-operation-list-dialog': ItemOperationListDialog,
    'operation-guidence-images': OperationGuidenceImages,
    'file-uploader': FileUploader,
    'time-picker-dialog': TimePickerDialog,
    'item-search': ItemSearch,
    'task-frequency-field': TaskFrequencyField,
    'task-color-select': TaskColorSelect,
    'task-notification-field': TaskNotificationField,
    'task-report-tag-field': TaskReportTagField,
    'task-shift-part-field': TaskShiftPartField
  },
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const { isUserAccountManager } = useAuth()
    const dialog = ref(false)
    const shiftParts = ref(OpModuleShiftParts())
    const preExistingListItems = ref(false)
    const dialogMode = ref(DialogMode.create)
    const opImages = ref(null)
    const opFile = ref(null)
    const itemOperationListDialog = ref(null)
    const operationForm = ref(null)
    const moreDetailsOpen = ref(false)

    let operationSchedule = null
    let originalJobId = null
    const searchForOperation = ref(false)
    const {
      isLoading, newOperation, requireImage, shiftPart, startTime, endDate, frequencyModel,
      currentJobSearchResult, saveDisabled, hasListItem, activeDays, frequencyExectDate, frequencyYearDate,
      shiftPartDisabled, opItemList, relatedItem, reportTag, taskNotifications,
      operationHasStartTime, isJobSelected,
      onJobSelect, setOperation, updateWorkingDays,
      clearColor, save, reset, changeApiKey
    } = useNewOperation(props.apiKey)

    // Methods
    const toggleMoreDetails = () => (moreDetailsOpen.value = !moreDetailsOpen.value)
    const toggleRequireImage = () => (requireImage.value = !requireImage.value)

    const openDialog = () => {
      dialog.value = true
      dialogMode.value = DialogMode.create
      operationSchedule = null
      reset()
      shiftPart.value = shiftParts.value.find((shiftPart) => shiftPart.name === 'start')
      if (isUserAccountManager.value === true) {
        searchForOperation.value = true
      }
    }

    const openForUpdate = async ({ operation }) => {
      dialog.value = true
      dialogMode.value = DialogMode.update
      preExistingListItems.value = Array.isArray(operation.item_operation_list)
      originalJobId = operation.account_job_id
      if (isString(operation.account_api_key)) {
        changeApiKey(operation.account_api_key)
      }
      operationSchedule = await setOperation({
        operation,
        jobId: operation.account_job_id,
        shiftPartName: operation.shift_part,
        hasItemList: preExistingListItems.value
      })
      nextTick(() => {
        opImages.value.setImages(operationSchedule.guidance_images)
        opFile.value.setFile(operationSchedule.guidance_file)
      })
    }

    const openForCopy = async ({ operation }) => {
      dialog.value = true
      dialogMode.value = DialogMode.copy
      const hasItemList = Array.isArray(operation.item_operation_list)

      operationSchedule = await setOperation({
        operation,
        jobId: operation.account_job_id,
        shiftPartName: operation.shift_part,
        hasItemList,
        copyMode: true
      })
      if (!hasItemList) return

      opItemList.value = operation.item_operation_list
        .map(item => ({ description: item.description, item_type: item.item_type }))
    }

    const taskNotificationChange = ({ taskComplete, taskUncomplete } = {}) => {
      taskNotifications.value.taskComplete = taskComplete
      taskNotifications.value.taskUncomplete = taskUncomplete
    }
    const openOperationListItem = () => {
      const oldList = JSON.parse(JSON.stringify(opItemList.value))
      if (dialogMode.value === DialogMode.update) {
        const itemOperationId = operationSchedule.item_operation_id
        itemOperationListDialog.value.openDialog(operationSchedule.item_id, itemOperationId)
          .then(({ hasListItems }) => (preExistingListItems.value = hasListItems))
      } else {
        itemOperationListDialog.value.openDialogWithExistingList(opItemList.value)
          .then(({ isSaved, itemList }) => (opItemList.value = isSaved ? itemList : oldList))
      }
    }

    const saveClick = () => {
      const uploadImageCount = opImages.value.uploadImageCount()
      const saveImageCall = opImages.value.saveImages
      const saveFileCall = opFile.value.saveFile
      const attachedFileName = opFile.value.attachedFileName()
      save({ uploadImageCount, saveImageCall, attachedFileName, saveFileCall })
        .then(() => {
          const jobId = Number.isInteger(originalJobId) ? originalJobId : currentJobSearchResult.value.id
          EventBus.emit(NEW_ACCOUNT_OPERATION, { jobId })
          close()
        })
    }

    const close = () => {
      reset()
      dialog.value = false
      preExistingListItems.value = false
      operationSchedule = null
      originalJobId = null
      operationForm.value.reset()
      opImages.value.reset()
      opFile.value.reset()
      if (isUserAccountManager.value === true) {
        searchForOperation.value = false
      }
    }

    // Computed
    const isUpdateMode = computed(() => dialogMode.value === DialogMode.update)
    return {
      isLoading,
      newOperation,
      searchForOperation,
      currentJobSearchResult,
      saveDisabled,
      hasListItem,
      requireImage,
      shiftPart,
      startTime,
      endDate,
      shiftPartDisabled,
      frequencyExectDate,
      frequencyYearDate,
      activeDays,
      frequencyModel,
      dialog,
      isUpdateMode,
      opImages,
      opFile,
      moreDetailsOpen,
      itemOperationListDialog,
      operationForm,
      relatedItem,
      reportTag,
      isRtl,
      operationHasStartTime,
      isJobSelected,
      openDialog,
      clearColor,
      openOperationListItem,
      onJobSelect,
      updateWorkingDays,
      saveClick,
      close,
      openForUpdate,
      openForCopy,
      toggleMoreDetails,
      toggleRequireImage,
      taskNotificationChange
    }
  }
}
</script>
<style lang="scss">
.new-task-dialog {
  .v-text-field--outlined fieldset  {
    border-color: #E8E8E8 ;
  }
  .save-btn.v-btn--disabled {
    color: white !important
  }
  .switch-div {
    height: 59px;
    border: 1px solid #E8E8E8;
    border-radius: 2px;
    background-color: white;
    color: rgba(0, 0, 0, 0.7);
    .col.disabled {
      color: rgba(0, 0, 0, 0.38);
      pointer-events: none;
    }
  }
  .file-input {
    .v-chip__content {
      span {
        color: #212121;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        padding-left: 0px !important;
        padding-right: 0px !important;
      }
    }
  }
}
</style>
