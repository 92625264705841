<template>
  <span>
    <new-device-dialog
      v-if="userCanCreateItem"
      ref="newDeviceDialog"
      :item-place-holder="newItemPlaceHolder"
      :api-key="apiKey"
    />
    <new-item-category
      v-if="userCanCreateOrEditItemCategory"
      ref="newItemCategoryDialog"
      :api-key="apiKey"
    />
    <confirm-delete ref="deleteDialog" />
    <v-autocomplete
      :value="value"
      :items="selectEntries"
      :loading="selectIsLoading"
      :search-input.sync="search"
      clearable
      hide-selected
      append-icon="search"
      class="item-search"
      color="black"
      return-object
      :no-data-text="$t('no_result')"
      item-value="id"
      item-text="nameAndDepartment"
      :disabled="disabled"
      :menu-props="menuPropsWithClass"
      :placeholder="placeHolder"
      :background-color="backgroundColor"
      :outlined="outlined"
      :hide-details="hideDetails"
      @change="emitChange"
      @click:clear="clear"
      @input="update"
    >
      <template v-if="isRequired" #label>
        {{ placeHolder }} <span class="red--text">*</span>
      </template>
      <template #prepend-item>
        <v-row
          align="center" justify="start" no-gutters
          :class="{ rtl: isRtl }"
        >
          <v-col cols="6" class="mt-3 pps-2">
            <item-category-search
              v-model="itemCategory"
              :api-key="apiKey"
            />
          </v-col>
        </v-row>
      </template>
      <template #item="{ item }">
        <v-row
          v-if="item.isHeader" :class="{ rtl: isRtl }"
          align="center" justify="space-between" no-gutters
          class="category"
          @click.stop
        >
          <span class="pps-2">
            <strong>{{ item.name }} </strong>
          </span>
          <v-menu
            v-if="item.showOptions"
            offset-y
          >
            <template #activator="{ on }">
              <v-icon color="black" v-on="on">more_vert</v-icon>
            </template>
            <v-list>
              <v-list-item ripple @click="editItemCategory(item)">
                <v-list-item-icon>
                  <v-icon small>edit</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ $t('edit') }}</v-list-item-title>
              </v-list-item>
              <v-list-item ripple @click="deleteItemCategory(item)">
                <v-list-item-icon>
                  <v-icon small>delete</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ $t('delete') }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-row>
        <v-list-item v-else-if="item.isButton" :class="{ rtl: isRtl }" @click.stop="addNewItem(item.categoryId)">
          <v-list-item-content>
            <v-list-item-title class="t-500-16 color20">
              <v-icon color="#111111" class="ppe-2">add_circle_outline</v-icon>
              {{ $t('device_device_new_dialog.create_for_category') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-row
          v-else :class="{ rtl: isRtl }" align="center"
          justify="space-between"
        >
          <span class="pps-1">
            <span class="t-400-16"> {{ item.name }} &nbsp;  {{ departmentText(item) }}</span>
            <span v-if="searchContext" class="t-700-13">
              {{ categoryText(item) }}
            </span>
          </span>
          <v-menu
            v-if="!item.isGeneral && (showEditItemOption || showDeleteItemOption)"
            offset-y
          >
            <template #activator="{ on }">
              <v-icon color="black" v-on="on">more_vert</v-icon>
            </template>
            <v-list>
              <v-list-item v-if="showEditItemOption" ripple @click="editItem(item)">
                <v-list-item-icon>
                  <v-icon small>edit</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ $t('edit') }}</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="showDeleteItemOption" ripple @click="deleteItem(item.id)">
                <v-list-item-icon>
                  <v-icon small>delete</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ $t('delete') }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-row>
      </template>

      <template #selection="{ item }">
        <div :class="{ rtl: isRtl }" class="color5 t-400-16">
          <span> {{ item.name }}</span>
          <span class="mms-1">{{ departmentText(item) }}</span>
          <span>
            {{ categoryText(item) }}
          </span>
        </div>
      </template>
    </v-autocomplete>
  </span>
</template>

<script>
import NewDeviceDialog from '@/components/shared/DevicesNewDevice.vue'
import ItemCategorySearch from '@/components/shared/item_category_search/ItemCategorySearch.vue'
import NewItemCategory from '@/components/shared/NewItemCategory.vue'
import ConfirmDelete from '@/components/shared/ConfirmDelete.vue'

import { search as itemsClientSearch, destroy as itemsClientDestroy } from '@/api_client/Device.js'
import {
  search as searchItemCategories,
  destroy as itemCategoryClientDelete
} from '@/api_client/ItemCategory.js'

import { isString } from '@/helpers/Utils.js'

import useItemSearchStore from '@/components/shared/item_search/useItemSearchStore.js'
import useCurrentUserPermissions from '@/composables/useCurrentUserPermissions.js'
import useAuth from '@/composables/useAuth.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { ref, watch, computed } from 'vue'

import { i18n, isRtl } from '@/i18n.js'
export default {
  components: {
    'new-device-dialog': NewDeviceDialog,
    'confirm-delete': () => ConfirmDelete,
    'item-category-search': ItemCategorySearch,
    'new-item-category': NewItemCategory
  },
  props: {
    value: {
      type: Object,
      default: null
    },
    placeHolder: {
      type: String,
      default: function () {
        return i18n.t('item_search.placeholder')
      },
      required: false
    },
    loadAll: {
      type: Boolean,
      default: false,
      required: false
    },
    menuProps: {
      type: Object,
      required: false,
      default: () => { }
    },
    showAddItem: {
      type: Boolean,
      default: false,
      required: false
    },
    apiKey: {
      type: String,
      required: true
    },
    newItemPlaceHolder: {
      type: String,
      default: null,
      required: false
    },
    showDeleteButton: {
      type: Boolean,
      default: false,
      required: false
    },
    showEditButton: {
      type: Boolean,
      default: false,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    backgroundColor: {
      type: String,
      required: false,
      default: undefined
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false
    },
    hideDetails: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup (props, { emit }) {
    const entries = ref([])
    const isSearchLoading = ref(false)
    const search = ref(null)
    const newDeviceDialog = ref(null)
    const newItemCategoryDialog = ref(null)
    const deleteDialog = ref(null)
    const itemCategory = ref(null)
    const emptyCategories = ref([])

    const { isAccountAdminOrAbove } = useAuth()
    const { allItems, invalidateCache, loadAllItems, changeApiKey, isLoading } = useItemSearchStore(props.apiKey)
    const { canCreateItem, canDeleteItem, canEditItem, loadPermissions } = useCurrentUserPermissions(props.apiKey)

    const reLoadAllItems = () => loadAllItems().catch(errHandler)

    const setGeneralItemInResults = (result = []) => {
      const generalItem = result.find((item) => item.general === true)
      if (!generalItem) return

      generalItem.name = i18n.t('devices.general_name')
      generalItem.department_name = null
      generalItem.isGeneral = true
    }

    const loadEmptyCategories = () =>
      searchItemCategories(props.apiKey, { withoutItems: true })
        .then(({ data: { data } }) => (emptyCategories.value = data))
        .catch(errHandler)

    const buttonOption = ({ categoryId }) => ({
      id: `${categoryId}_button`,
      isButton: true,
      categoryId
    })

    const categoryOption = ({ currentCategoryId, categoryName }) => {
      const emptyCategory = currentCategoryId === null
      const showOptions = !emptyCategory && userCanCreateOrEditItemCategory.value
      return {
        id: `${currentCategoryId}_header`,
        categoryId: currentCategoryId,
        isHeader: true,
        name: currentCategoryId === null ? i18n.t('without_category') : categoryName,
        showOptions
      }
    }

    // Methods
    const update = (newValue) => emit('input', newValue)
    const emitChange = (newValue) => emit('change', newValue)
    const clear = () => emit('click:clear')
    const departmentText = (item) =>
      isString(item.department_name) && item.department_name.length > 0
        ? `- ${item.department_name}`
        : ''

    const categoryText = (item) => {
      if (!isString(item.category_name)) return ''
      return `(${item.category_name})`
    }

    const refreshItems = () => {
      invalidateCache()
      reLoadAllItems()
      loadEmptyCategories()
    }

    const addNewItem = (categoryId) => {
      newDeviceDialog.value.openForCreate(categoryId).then(({ updatedOrCreated, itemId }) => {
        if (updatedOrCreated !== true) return

        reLoadAllItems().then(() => {
          const item = selectEntries.value.find((item) => item.id === itemId)
          if (!item) return
          update(item)
        })
      })
    }

    const editItemCategory = (itemCategory) => {
      const category = {
        id: itemCategory.categoryId,
        name: itemCategory.name
      }
      newItemCategoryDialog.value.openForUpdate(category).then(({ updatedOrCreated }) => {
        if (updatedOrCreated !== true) return

        refreshItems()
      })
    }

    const deleteItemCategory = (item) => {
      const title = i18n.t('item_category_search.delete_title')
      const content = i18n.t('item_category_search.delete_content')
      deleteDialog.value.open({ title, content })
        .then((shouldDelete) => {
          if (!shouldDelete) return
          itemCategoryClientDelete(props.apiKey, item.id)
            .then(() => {
              deleteDialog.value.close()
              refreshItems()
            })
        })
    }

    const deleteItem = (itemId) => {
      const title = i18n.t('item_search.delete_title')
      const content = i18n.t('item_search.delete_content')
      deleteDialog.value.open({ title, content })
        .then((shouldDelete) => {
          if (!shouldDelete) return
          itemsClientDestroy(props.apiKey, itemId)
            .then(() => {
              emit('item-deleted')
              deleteDialog.value.close()
              refreshItems()
            })
        })
    }

    const editItem = (item) => {
      newDeviceDialog.value.openForUpdate(item).then(({ updatedOrCreated, itemId }) => {
        if (updatedOrCreated !== true) return

        reLoadAllItems().then(() => {
          const item = selectEntries.value.find((item) => item.id === itemId)
          if (!item) return
          update(item)
        })
      })
    }
    // Computed

    const selectIsLoading = computed(() => isSearchLoading.value || isLoading.value)
    const isCategorySelected = computed(() => Number.isInteger(itemCategory.value?.id))
    const userCanCreateItem = computed(() => props.showAddItem && canCreateItem.value)
    const showEditItemOption = computed(() => props.showEditButton && canEditItem.value)
    const showDeleteItemOption = computed(() => props.showDeleteButton && canDeleteItem.value)
    const userCanCreateOrEditItemCategory = computed(() => props.showAddItem && isAccountAdminOrAbove.value)
    const searchContext = computed(() => isString(search.value) && search.value.length > 0)
    const menuPropsWithClass = computed(() => {
      const menuProps = props.menuProps || {}
      if (isString(menuProps.contentClass)) {
        menuProps.contentClass += ' item-search-menu'
      } else {
        menuProps.contentClass = 'item-search-menu'
      }
      return menuProps
    })
    const selectEntries = computed(() => {
      let selectedRef = []
      if (isString(search.value) && search.value.length > 0) {
        selectedRef = entries.value
      } else {
        selectedRef = allItems.value
      }
      const result = []
      let lastCategoryId = -1
      const categoryIdsSet = new Set()
      if (isCategorySelected.value) categoryIdsSet.add(itemCategory.value.id)
      selectedRef.forEach((item) => {
        if (isCategorySelected.value && !categoryIdsSet.has(item.category_id)) return

        const itemClone = Object.assign({}, item)
        itemClone.nameAndDepartment = `${item.name} ${departmentText(item)}`
        const currentCategoryIdNum = parseInt(item.category_id)
        const currentCategoryId = Number.isInteger(currentCategoryIdNum) ? currentCategoryIdNum : null
        if (currentCategoryId !== lastCategoryId) {
          result.push(categoryOption({ currentCategoryId, categoryName: itemClone.category_name }))
          if (userCanCreateItem.value) {
            result.push(buttonOption({ categoryId: currentCategoryId }))
          }
          lastCategoryId = currentCategoryId
        }
        result.push(itemClone)
      })
      setGeneralItemInResults(result)
      if (!userCanCreateItem.value) return result

      if (isCategorySelected.value) {
        const selectedCategoryId = itemCategory.value.id
        const categoryIndex = emptyCategories.value.findIndex((category) => category.id === selectedCategoryId)
        if (categoryIndex > -1) {
          const category = emptyCategories.value[categoryIndex]
          result.push(categoryOption({ currentCategoryId: category.id, categoryName: category.name }))
          result.push(buttonOption({ categoryId: category.id }))
        }
      } else {
        emptyCategories.value.forEach((category) => {
          result.push(categoryOption({ currentCategoryId: category.id, categoryName: category.name }))
          result.push(buttonOption({ categoryId: category.id }))
        })
      }
      return result
    })

    const itemId = (props.value || {}).id
    if (Number.isInteger(itemId)) {
      entries.value.push(props.value)
    }

    watch(() => search.value, (query) => {
      if (isSearchLoading.value || query === null) return
      if (!query || (query || '').trim().length < 1) {
        entries.value.splice(0, entries.value.length)
        return
      }

      isSearchLoading.value = true
      const reuqestParams = {
        term: query
      }
      if (isCategorySelected.value) {
        reuqestParams.categoryId = itemCategory.value.id
      }
      itemsClientSearch(props.apiKey, reuqestParams)
        .then(res => {
          const results = res.data.data
          setGeneralItemInResults(results)
          const entriesVal = entries.value
          results.forEach(item => entriesVal.push(item))
        })
        .catch(errHandler)
        .finally(() => (isSearchLoading.value = false))
    })

    watch(() => props.apiKey, (newValue) => {
      if (!isString(newValue)) return
      changeApiKey(newValue)
      refreshItems()
    })

    reLoadAllItems()
    loadPermissions()
    loadEmptyCategories()
    return {
      entries,
      isSearchLoading,
      search,
      selectIsLoading,
      selectEntries,
      searchContext,
      newDeviceDialog,
      newItemCategoryDialog,
      userCanCreateItem,
      userCanCreateOrEditItemCategory,
      showEditItemOption,
      showDeleteItemOption,
      canDeleteItem,
      canEditItem,
      deleteDialog,
      isCategorySelected,
      menuPropsWithClass,
      isRtl,
      itemCategory,
      update,
      emitChange,
      clear,
      departmentText,
      categoryText,
      refreshItems,
      addNewItem,
      editItemCategory,
      deleteItemCategory,
      deleteItem,
      editItem
    }
  }
}
</script>

<style lang="scss">
.item-search-menu {
  div.category {
    background-color: #E8E8E8;
    font-weight: 500;
    font-size: 16px;
    height: 36px;
    cursor: auto;
  }
}

.item-search.v-select.v-select--is-menu-active .v-input__icon--append .v-icon {
  transform: rotate(0);
}
</style>
