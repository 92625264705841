<template>
  <v-dialog
    v-model="dialog" persistent
    :width="540" scrollable
    content-class="rounded-xl"
  >
    <v-card
      class="bg-color17 frequency-dialog"
      :class="{ 'rtl': isRtl, 'ltr': !isRtl }"
    >
      <v-card-title class="white px-1">
        <v-row align="center" justify="start" no-gutters>
          <v-col cols="3" class="texts-start">
            <v-btn
              icon
              color="pink"
              @click="close"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="6" class="text-center color5 t-600-18">
            {{ $t('operation_frequency.exect_date_frequency') }}
          </v-col>
          <v-col cols="3" class="texts-end ppe-1 t-400-17 color22">
            <span class="pointer" @click="onReset">
              {{ $t('reset') }}
            </span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row align="start" justify="center">
          <v-col cols="10" class="text-center">
            <v-date-picker
              v-model="selectedDates"
              type="date" scrollable
              show-current :min="datesDialogMinDate" range
            />
          </v-col>
          <v-col cols="10" class="texts-start t-700-16 color5">
            <div v-if="isRange">
              {{ $t('from') }} <span class="color3 t-400-16">{{ dateRangeSortedUserFormat[0] }}</span>
              <br>
              {{ $t('to') }} <span class="color3 t-400-16">{{ dateRangeSortedUserFormat[1] }}</span>
            </div>
            <div v-else-if="isSingleDate">
              {{ $t('date') }}: <span class="color3 t-400-16">{{ dateRangeSortedUserFormat[0] }}</span>
            </div>
            <div class="t-400-16 color2">
              <sup>*</sup>{{ $t('operation_frequency.exact_date_command') }}
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row align="center" justify="center">
          <v-col cols="7">
            <v-btn
              color="black" class="save-btn white--text" block
              depressed tile
              :disabled="saveDisabled"
              @click="saveClick"
            >
              {{ $t('save') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { dateToISO8601String, isValidISO8601Date, ISO8601StringToDate, getClientDateFormatted } from '@/helpers/DateTime.js'

import useAccount from '@/composables/useAccount.js'

import { isRtl } from '@/i18n.js'

import { ref, computed } from 'vue'
export default {
  setup (props, { emit }) {
    let resolveFunc = null
    const dialog = ref(null)
    const selectedDates = ref([])
    const datesDialogMinDate = dateToISO8601String(new Date())
    const { accountCountryCode } = useAccount()

    // Methods
    const openDialog = ({ date = null, endDate = null } = {}) => {
      dialog.value = true
      selectedDates.value = []
      if (isValidISO8601Date(date)) {
        selectedDates.value.push(date)
      }
      if (isValidISO8601Date(endDate)) {
        selectedDates.value.push(endDate)
      }

      return new Promise((resolve) => {
        resolveFunc = resolve
      })
    }

    const saveClick = () => close({ isSaved: true })
    const onReset = () => close({ isSaved: true, isReset: true })

    const close = ({ isSaved = false, isReset = false } = {}) => {
      dialog.value = false
      if (!isSaved) return resolveFunc({ isSaved })
      if (isReset) return resolveFunc({ isSaved, isReset })

      const result = { isSaved, date: null, endDate: null }
      const dateRangeVal = dateRangeSorted.value
      if (dateRangeVal.length === 1) {
        result.date = dateRangeVal[0]
      } else if (dateRangeVal.length === 2) {
        result.date = dateRangeVal[0]
        result.endDate = dateRangeVal[1]
      }
      resolveFunc(result)
    }

    // Computed
    const isRange = computed(() => selectedDates.value.length === 2)
    const isSingleDate = computed(() => selectedDates.value.length === 1)
    const saveDisabled = computed(() => selectedDates.value.length === 0)
    const dateRangeSorted = computed(() => {
      const result = []

      if (isSingleDate.value) {
        result.push(selectedDates.value[0])
      }
      if (isRange.value && selectedDates.value.every(isValidISO8601Date)) {
        const dateArrays = selectedDates.value.map(ISO8601StringToDate)
        if (dateArrays[0] <= dateArrays[1]) {
          result.push(selectedDates.value[0])
          result.push(selectedDates.value[1])
        } else {
          result.push(selectedDates.value[1])
          result.push(selectedDates.value[0])
        }
      }
      return result
    })

    const dateRangeSortedUserFormat = computed(() =>
      dateRangeSorted.value.map(date => {
        const fromDate = ISO8601StringToDate(date)
        return getClientDateFormatted({ fromDate, countryCode: accountCountryCode.value })
      })
    )
    return {
      dialog,
      isRtl,
      saveDisabled,
      datesDialogMinDate,
      selectedDates,
      isRange,
      isSingleDate,
      dateRangeSortedUserFormat,
      openDialog,
      close,
      saveClick,
      onReset
    }
  }
}
</script>
